<template>
  <!--  $0.scrollTo({top: 0,behavior:'smooth'}) -->
  <div>
    <div v-if="mediaQueryPC">
      <div class="head">
        <img src="@/assets/img/logo.png" alt="" class="logo">
        <span class="head-text home active" @click="scrollTo(0,0)">首页</span>
        <span class="head-text us" @click="scrollTo(0,660)">关于我们</span>
        <span class="head-text business" @click="scrollTo(0,1215)">业务模块</span>
        <span class="head-text partner" @click="scrollTo(0,9999)">合作伙伴</span>
      </div>
      <div class="banner">
        <el-carousel arrow="never" trigger="click" :interval="5000" style="height: 720px;width: 100%">
          <el-carousel-item style="height: 720px;width: 100%">
            <img src="../assets/banner/banner1.png" alt="" style="height: 720px;width: 100%">
          </el-carousel-item>
          <el-carousel-item style="height: 720px;width: 100%">
            <img src="../assets/banner/banner2.png" alt="" style="height: 720px;width: 100%">
          </el-carousel-item>
<!--          <el-carousel-item style="height: 720px;width: 100%">-->
<!--            <img src="../assets/banner/banner3.png" alt="" style="height: 720px;width: 100%">-->
<!--          </el-carousel-item>-->
        </el-carousel>
        <!--      <div class="banner-text">-->
        <!--        数智两航-->
        <!--        敦行致远-->
        <!--      </div>-->
        <!--      <div class="banner-text-shade">-->
        <!--        数智两航-->
        <!--        敦行致远-->
        <!--      </div>-->
      </div>
      <div style="width: 100%;height: 659px;"></div>
      <div class="aboutUs">
        <div class="title">关于我们</div>
        <p class="aboutUs-text">
          两航（天津）数字科技有限公司是由联盟技术创新中心、国有科技企业、联盟民营运营平台共同成立的技术创新产业化公司。公司基于各方优势资源，着眼于数字化转型、智能化、网联化产业创新的核心目标，以数字经济、科技创新服务为基础，结合数字孪生、数字伴生、智能决策等新一代信息技术，致力为数字经济产业提供一站式解决方案及技术支撑。
        </p>
        <div style="width: 890px;margin: auto;display: flex;justify-content: space-between">
          <div class="aboutUs-icon-box">
            <img class="aboutUs-icon" src="@/assets/img/us_icon1.png" alt="">
            <div class="aboutUs-icon-text">开发硬实力</div>
          </div>
          <div class="aboutUs-icon-box">
            <img class="aboutUs-icon" src="@/assets/img/us_icon2.png" alt="">
            <div class="aboutUs-icon-text">设计创造力</div>
          </div>
          <div class="aboutUs-icon-box">
            <img class="aboutUs-icon" src="@/assets/img/us_icon3.png" alt="">
            <div class="aboutUs-icon-text">渠道整合力</div>
          </div>
          <div class="aboutUs-icon-box">
            <img class="aboutUs-icon" src="@/assets/img/us_icon4.png" alt="">
            <div class="aboutUs-icon-text">内容呈现力</div>
          </div>
          <div class="aboutUs-icon-box">
            <img class="aboutUs-icon" src="@/assets/img/us_icon5.png" alt="">
            <div class="aboutUs-icon-text">战略洞察力</div>
          </div>
        </div>
      </div>
      <div class="businessBox" :style="{width : Width + 'px'}">
        <div class="scene-wrapper scene-wrapper1" :style="{width : Width2 + 'px'}" @mouseenter="mouseIn1()" @mouseleave="mouseOut1()">
          <div class="scene-wrapper-img"></div>
          <div class="scene-wrapper-bg">
            <div class="item-thumb">
              <div class="businessBox-title">平台开发</div>
              <div class="businessBox-text">为用户提供数字生态系统开发</div>
            </div>
            <div class="item-hover">
              <div class="hover-top">
                <div class="hover-title">平台开发</div>
                <div class="hover-text">依托全栈自研技术，为用户提供数字生态系统开发，包含系统平台定制化设计及开发、集团/品牌官网、微信小程序等。</div>
              </div>
              <div class="hover-bottom">
                <div class="hover-bottom-left">
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive===1}">
                    <div @click="imagesActive=1" style="position: relative;cursor: pointer"><img v-if="imagesActive===1" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt=""> 智能决策</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive===2}">
                    <div @click="imagesActive=2" style="position: relative;cursor: pointer"><img v-if="imagesActive===2" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt=""> 系统管理</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive===3}">
                    <div @click="imagesActive=3" style="position: relative;cursor: pointer"><img v-if="imagesActive===3" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt=""> 运维服务</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive===1">
                  <div>
                    <img src="../assets/cpt/znjc1.png" class="hover-image" alt="">
                    <div class="hover-image-text">茶淀智慧运维平台</div>
                  </div>
                  <div>
                    <img src="../assets/cpt/znjc2.png" class="hover-image" alt="">
                    <div class="hover-image-text">市应急局智能安全管理</div>
                  </div>
                  <div>
                    <img src="../assets/cpt/znjc3.png" class="hover-image" alt="">
                    <div class="hover-image-text">智慧机场运管平台</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive===2">
<!--                  <div>-->
<!--                    <img src="../assets/cpt/xtgl1.png" class="hover-image" alt="">-->
<!--                    <div class="hover-image-text">灵活用工平台</div>-->
<!--                  </div>-->
                  <div>
                    <img src="../assets/cpt/xtgl2.png" class="hover-image" alt="">
                    <div class="hover-image-text">企业数字化办公平台</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive===3">
                  <div>
                    <img src="../assets/cpt/ywfw1.png" class="hover-image" alt="">
                    <div class="hover-image-text">东丽湖生活圈</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="scene-wrapper scene-wrapper2" :style="{width : Width2 + 'px'}" @mouseenter="mouseIn2()" @mouseleave="mouseOut2()">
          <div class="scene-wrapper-img"></div>
          <div class="scene-wrapper-bg">
            <div class="item-thumb">
              <div class="businessBox-title">数字展陈</div>
              <div class="businessBox-text">为企业提供一站式数字化展览展示综合解决方案</div>
            </div>
            <div class="item-hover">
              <div class="hover-top">
                <div class="hover-title">数字展陈</div>
                <div class="hover-text">从需求出发，利用三维建模和数字展陈技术，为企业提供一站式数字化展览展示综合解决方案，从线下到线上，实现多元化同步展示的效果。</div>
              </div>
              <div class="hover-bottom">
                <div class="hover-bottom-left">
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive2===1}">
                    <div @click="imagesActive2=1" style="position: relative;cursor: pointer"><img v-if="imagesActive2===1" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">企业展厅</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive2===2}">
                    <div @click="imagesActive2=2" style="position: relative;cursor: pointer"><img v-if="imagesActive2===2" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">城市规划馆</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive2===3}">
                    <div @click="imagesActive2=3" style="position: relative;cursor: pointer"><img v-if="imagesActive2===3" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">主题展馆</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive2===4}">
                    <div @click="imagesActive2=4" style="position: relative;cursor: pointer"><img v-if="imagesActive2===4" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">线上云展厅</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive2===1">
                  <div>
                    <img src="../assets/cpt/qyzt1.png" class="hover-image"  alt="">
                    <div class="hover-image-text">航大天元数字展示空间</div>
                  </div>
<!--                  <div>-->
<!--                    <img src="../assets/cpt/qyzt2.png" class="hover-image"  alt="">-->
<!--                    <div class="hover-image-text">河东技术创新中心</div>-->
<!--                  </div>-->
                </div>
                <div class="hover-bottom-right" v-if="imagesActive2===2">
                  <div>
                    <img src="../assets/cpt/csghg1.png" class="hover-image"  alt="">
                    <div class="hover-image-text">茶淀数智乡村创新创业示范基地</div>
                  </div>
<!--                  <div>-->
<!--                    <img src="../assets/cpt/csghg2.png" class="hover-image"  alt="">-->
<!--                    <div class="hover-image-text">东丽经开区城市会客厅</div>-->
<!--                  </div>-->
                </div>
                <div class="hover-bottom-right" v-if="imagesActive2===3">
                  <div>
                    <img src="../assets/cpt/ztzg1.png" class="hover-image"  alt="">
                    <div class="hover-image-text">河北文旅</div>
                  </div>
                  <div>
                    <img src="../assets/cpt/ztzg2.png" class="hover-image"  alt="">
                    <div class="hover-image-text">津门里党群服务中心</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive2===4">
                  <div>
                    <img src="../assets/cpt/xsyzt1.png" class="hover-image"  alt="">
                    <div class="hover-image-text">中国旅游博览会</div>
                  </div>
                  <div>
                    <img src="../assets/cpt/xsyzt2.png" class="hover-image"  alt="">
                    <div class="hover-image-text">中汽信科科技虚拟展厅</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="scene-wrapper scene-wrapper3" :style="{width : Width2 + 'px'}" @mouseenter="mouseIn3()" @mouseleave="mouseOut3()">
          <div class="scene-wrapper-img"></div>
          <div class="scene-wrapper-bg">
            <div class="item-thumb">
              <div class="businessBox-title">项目策划</div>
              <div class="businessBox-text">提供全案创意策划+设计实施</div>
            </div>
            <div class="item-hover">
              <div class="hover-top">
                <div class="hover-title">项目策划</div>
                <div class="hover-text">两航数科提供全案创意策划+设计实施，充分发挥创意与科技的核心竞争力，助力释放更大市场价值。</div>
              </div>
              <div class="hover-bottom">
                <div class="hover-bottom-left">
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive3===1}">
                    <div style="position: relative;cursor:pointer;" @click="imagesActive3=1">
                      <img v-if="imagesActive3===1" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">
                      文旅夜游
                    </div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive3===2}">
                    <div style="position: relative;cursor:pointer;" @click="imagesActive3=2"><img v-if="imagesActive3===2" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">活动策划</div>
                  </div>
                  <div class="hover-noneactive" :class="{'hover-active':imagesActive3===3}">
                    <div style="position: relative;cursor:pointer;" @click="imagesActive3=3"><img v-if="imagesActive3===3" style="width: 13px;height: 13px;position: absolute;top: 8px;left: -18px;" src="../assets/img/jt.png" alt="">数字营销</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive3===1">
                  <div>
                    <img src="../assets/cpt/wlyy1.png" class="hover-image" alt="">
                    <div class="hover-image-text"></div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive3===2">
                  <div>
                    <img src="../assets/cpt/hdch1.png" class="hover-image" alt="">
                    <div class="hover-image-text">中汽中心政府智库专家委员会</div>
                  </div>
                </div>
                <div class="hover-bottom-right" v-if="imagesActive3===3">
<!--                  <div>-->
<!--                    <img src="../assets/cpt/szyx1.png" class="hover-image" alt="">-->
<!--                    <div class="hover-image-text">滨航企服宣传视频</div>-->
<!--                  </div>-->
                  <div>
                    <img src="../assets/cpt/szyx2.png" class="hover-image" alt="">
                    <div class="hover-image-text">东丽经开区人才视频</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partnerBox">
        <div class="title" id="hz">合作伙伴</div>
        <div style="display: flex;justify-content: center;margin-bottom: 20px;user-select: none">
          <div class="partnerBox-item">
            <img src="@/assets/img/partner1.png" alt="">
          </div>
          <div class="partnerBox-item" style="margin: 0 20px">
            <img src="@/assets/img/partner2.png" alt="">
          </div>
          <div class="partnerBox-item" style="margin-right: 20px">
            <img src="@/assets/img/partner3.png" alt="">
          </div>
          <div class="partnerBox-item">
            <img src="@/assets/img/partner5.png" alt="">
          </div>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 70px;user-select: none">
          <div class="partnerBox-item">
            <img src="@/assets/img/partner6.png" alt="">
          </div>
          <div class="partnerBox-item" style="margin: 0 20px">
            <img src="@/assets/img/partner7.png" alt="">
          </div>
          <div class="partnerBox-item" style="margin-right: 20px">
            <img src="@/assets/img/partner8.png" alt="">
          </div>
          <div class="partnerBox-item" style="box-shadow: none">
<!--            <img src="@/assets/img/partner4.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-top">
          <div class="contactInformation">
            <div style="margin-bottom: 28px;font-size: 14px">联系方式</div>
            <div style="margin-bottom: 21px">电话：15222555444</div>
            <div style="margin-bottom: 21px">邮箱：lhskgzh@163.com</div>
            <div>地址：天津市东丽区航双路空港国际总部基地A区A2号2001室</div>
          </div>
          <div class="joinUs">
            <div style="font-size: 14px;margin-bottom: 28px">关注或联系我们</div>
            <div style="margin-bottom: 23px">添加两航数科官方微信公众号，更多资讯服务案例等您开启</div>
            <div style="height: 82px;display: flex">
              <div class="joinUs-left">
                <img src="@/assets/img/ewm.jpg" alt="">
              </div>
              <div class="joinUs-right">
                <div style="margin-top: 11px">
                <span
                  style="font-size: 12px;font-weight: 400;font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;">资讯热线：</span>
                  <span
                    style="font-size:24px;font-weight: 400;font-family: DINAlternate-Bold-Regular, DINAlternate-Bold;">15222555444</span>
                </div>
                <div class="joinUs-button">
                  <div
                    style="font-size: 14px;font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;font-weight: 400;user-select: none">
                    加入我们
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <span style="margin-right: 35px">两航（天津）数字科技有限公司  津ICP证150476号 | 津ICP备11018762号|津公网安备号11010802020287</span>
          <span>Copyright © 2013 - 2023 lianghang Cloud. All Rights Reserved.  两航云  版权所有</span>
        </div>
      </div>
    </div>

    <div class="" ref="wrapper" v-if="!mediaQueryPC">
      <div id="section1" class="section">
        <div class="mobile-head" style="height: 3.125rem">
          <div style="display: flex;justify-content: space-between;height: 100%">
            <img src="../assets/mobileImg/logo.png" alt="" style="width: 5.22rem;height: 1.31rem;margin-top: 0.91rem;margin-left: 1.22rem">
            <img src="../assets/mobileImg/hamburger.png" @click="showExpand=!showExpand" alt="" style="width: 1.06rem;height: 1rem;margin-top: 1.06rem;margin-right: 1.19rem">
          </div>
          <div class="mobile-expand" :style="{height:showExpand?'14.5rem':'0rem',padding:showExpand?'2rem 0':'0'}">
            <div style="text-align: center;opacity: 0;transition: opacity 0.3s;transition-delay: 0.2s" :style="{opacity:showExpand?'1':'0'}" @click="showExpand && scrollToAnchor('#section1')">关于我们</div>
            <div style="text-align: center;opacity: 0;transition: opacity 0.3s;transition-delay: 0.2s" :style="{opacity:showExpand?'1':'0'}" @click="showExpand && scrollToAnchor('#section2')">平台开发</div>
            <div style="text-align: center;opacity: 0;transition: opacity 0.3s;transition-delay: 0.2s" :style="{opacity:showExpand?'1':'0'}" @click="showExpand && scrollToAnchor('#section3')">数字陈展</div>
            <div style="text-align: center;opacity: 0;transition: opacity 0.3s;transition-delay: 0.2s" :style="{opacity:showExpand?'1':'0'}" @click="showExpand && scrollToAnchor('#section4')">项目策划</div>
            <div style="text-align: center;opacity: 0;transition: opacity 0.3s;transition-delay: 0.2s" :style="{opacity:showExpand?'1':'0'}" @click="showExpand && scrollToAnchor('#section5')">联系我们</div>
          </div>
        </div>
        <div class="banner" style="height: 24.06rem">
          <el-carousel arrow="never" trigger="click" :interval="5000" style="height: 24.06rem;width: 100%">
            <el-carousel-item style="height: 24.06rem;width: 100%">
              <img src="../assets/banner/mobilebannner1.png" alt="" style="height: 24.06rem;width: 100%">
            </el-carousel-item>
            <el-carousel-item style="height: 24.06rem;width: 100%">
              <img src="../assets/banner/mobilebannner2.png" alt="" style="height: 24.06rem;width: 100%">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="height: 24rem"></div>
        <div class="mobile-title" style="margin-top: 1.84rem;margin-bottom: 1.25rem;text-align: center">关于我们</div>
        <div style="width: 20.09rem;height: 11.78rem;font-size: 0.88rem;font-weight: 400;color: #1A1A1A;line-height: 1.56rem;margin: auto;font-family: PingFang SC;">
          两航（天津）数字科技有限公司是由联盟技术创新中心、国有科技企业、联盟民营运营平台共同成立的技术创新产业化公司。公司基于各方优势资源，着眼于数字化转型、智能化、网联化产业创新的核心目标，以数字经济、科技创新服务为基础，结合数字孪生、数字伴生、智能决策等新一代信息技术，致力为数字经济产业提供一站式解决方案及技术支撑。
        </div>
        <div style="margin-top: 1.88rem;display: flex;justify-content: center;margin-bottom: 1.88rem;padding: 0 2.31rem">
          <div style="width: 2.72rem;height: 3.53rem;text-align: center;margin-right: 1.31rem">
            <img src="@/assets/mobileImg/kfysl.png" alt="" class="mobile-usImg" style="">
          </div>
          <div style="width: 2.72rem;height: 3.53rem;text-align: center;margin-right: 1.31rem">
            <img class="mobile-usImg" src="@/assets/mobileImg/sjczl.png" alt="" style="">
          </div>
          <div style="width: 2.72rem;height: 3.53rem;margin-right: 1.31rem">
            <img class="mobile-usImg" src="@/assets/mobileImg/qdzhl.png" alt="" style="">
          </div>
          <div style="width: 2.72rem;height: 3.53rem;margin-right: 1.31rem">
            <img class="mobile-usImg" src="@/assets/mobileImg/nrcxl.png" alt="" style="">
          </div>
          <div style="width: 2.72rem;height: 3.53rem;">
            <img class="mobile-usImg" src="@/assets/mobileImg/zldcl.png" alt="" style="">
          </div>
        </div>
      </div>
      <div id="section2" class="section">
        <div style="height: 100%;overflow: hidden">
          <div class="mobile-animationPage">
            <div class="title-box" ref="box1">
              <div class="mobile-animationPage-title">平台开发</div>
              <div class="mobile-animationPage-title2">为用户提供数字生态系统开发</div>
              <div class="mobile-animationPage-content">
                依托全栈自研技术，为用户提供数字生态系统开发
                包含系统平台定制化设计及开发、集团/品牌官网、微信小程序等。
              </div>
            </div>

            <div style="position: relative;display: flex;flex-direction: column">
              <div style="text-align: center;margin-top: 1.22rem;height: 28rem">
                <div class="imgbox1" v-show="mobileImgActive===1" ref="imgbox1">
                  <div class="parent mobile-img-box parent1">
                    <img src="../assets/cpt/znjc1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">茶淀智慧运维平台</span>
                    </div>
                  </div>
                  <div class="parent mobile-img-box parent1">
                    <img src="../assets/cpt/znjc2.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">市应急局智能安全管理</span>
                    </div>
                  </div>
                  <div class="parent mobile-img-box parent1">
                    <img src="../assets/cpt/znjc3.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">智慧机场运管平台</span>
                    </div>
                  </div>
                </div>
                <div class="imgbox1" v-show="mobileImgActive===2">
<!--                  <div class="parent mobile-img-box parent1">-->
<!--                    <img src="../assets/cpt/xtgl1.png" alt="" class="mobile-animation-img">-->
<!--                    <div class="parent-shadow">-->
<!--                      <span class="parent-shadow-text">-->
<!--                        灵活用工平台-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="parent mobile-img-box parent1">
                    <img src="../assets/cpt/xtgl2.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">
                        企业数字化办公平台
                      </span>
                    </div>
                  </div>

                </div>
                <div class="imgbox1" v-show="mobileImgActive===3">
                  <div class="parent mobile-img-box parent1">
                    <img src="../assets/cpt/ywfw1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">
                        东丽湖生活圈
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="position: absolute;left: 0.94rem;top: 4.56rem">
                <div style="width: 1.88rem;height: 1.88rem;margin-bottom: 0.5rem">
                  <img v-if="mobileImgActive!==1" @click="mobileImgActive=mobileImgActive-1" src="../assets/mobileImg/clickTop.png" alt="" style="display: block;width: 100%;height: 100%">
                </div>
                <div style="height: 0.5rem"></div>
                <div style="width: 1.88rem;height: 1.88rem;">
                  <img v-if="mobileImgActive!==3" @click="mobileImgActive=mobileImgActive+1" src="../assets/mobileImg/clickBottom.png" alt="" style="width: 100%;height: 100%;display: block">
                </div>
              </div>
            </div>
            <div style="padding: 0 0.94rem;display: flex;justify-content: space-between;margin-top: 2.47rem;">
              <div @click="mobileImgActive=1" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive===1}">智能决策</div>
              <div @click="mobileImgActive=2" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive===2}">系统管理</div>
              <div @click="mobileImgActive=3" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive===3}">运维服务</div>
            </div>
          </div>
        </div>
      </div>
      <div id="section3" class="section">
        <div style="height: 100%;overflow: hidden">
          <div class="mobile-animationPage2">
            <div class="title-box" ref="box2">
              <div class="mobile-animationPage-title">数字陈展</div>
              <div class="mobile-animationPage-title2">为企业提供一站式数字化展览展示综合解决方案</div>
              <div class="mobile-animationPage-content">
                从需求出发，利用三维建模和数字展陈技术，为企业提供一站式数字化展览展示综合解决方案，从线下到线上，实现多元化同步展示的效果。
              </div>
            </div>

            <div style="position: relative;display: flex;flex-direction: column">
              <div style="text-align: center;margin-top: 1.22rem;height: 28rem">
                <div class="imgbox2" v-show="mobileImgActive2===1" ref="imgbox2">
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/qyzt1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">
                        航大天元数字展示空间
                      </span>
                    </div>
                  </div>
<!--                  <div class="parent mobile-img-box parent2">-->
<!--                    <img src="../assets/cpt/qyzt2.png" alt="" class="mobile-animation-img">-->
<!--                    <div class="parent-shadow">-->
<!--                      <span class="parent-shadow-text">-->
<!--                        河东技术创新中心-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
                <div class="imgbox2" v-show="mobileImgActive2===2">
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/csghg1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">茶淀数智乡村创新创业示范基地</span>
                    </div>
                  </div>
<!--                  <div class="parent mobile-img-box parent2">-->
<!--                    <img src="../assets/cpt/csghg2.png" alt="" class="mobile-animation-img">-->
<!--                    <div class="parent-shadow">-->
<!--                      <span class="parent-shadow-text">东丽经开区城市会客厅</span>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
                <div class="imgbox2" v-show="mobileImgActive2===3">
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/ztzg1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">河北文旅</span>
                    </div>
                  </div>
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/ztzg2.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">津门里党群服务中心</span>
                    </div>
                  </div>
                </div>
                <div class="imgbox2" v-show="mobileImgActive2===4">
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/xsyzt1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">中国旅游博览会</span>
                    </div>
                  </div>
                  <div class="parent mobile-img-box parent2">
                    <img src="../assets/cpt/xsyzt2.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">中汽信科科技虚拟展厅</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="position: absolute;left: 0.94rem;top: 4.56rem">
                <div style="width: 1.88rem;height: 1.88rem;margin-bottom: 0.5rem">
                  <img v-if="mobileImgActive2!==1" @click="mobileImgActive2=mobileImgActive2-1" src="../assets/mobileImg/clickTop.png" alt="" style="display: block;width: 100%;height: 100%">
                </div>
                <div style="height: 0.5rem"></div>
                <div style="width: 1.88rem;height: 1.88rem;">
                  <img v-if="mobileImgActive2!==4" @click="mobileImgActive2=mobileImgActive2+1" src="../assets/mobileImg/clickBottom.png" alt="" style="width: 100%;height: 100%;display: block">
                </div>
              </div>
            </div>
            <div style="padding: 0 0.94rem;display: flex;justify-content: space-between;margin-top: 1.58rem;">
              <div @click="mobileImgActive2=1" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive2===1}">企业展厅</div>
              <div @click="mobileImgActive2=2" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive2===2}">城市规划馆</div>
              <div @click="mobileImgActive2=3" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive2===3}">主题展馆</div>
              <div @click="mobileImgActive2=4" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive2===4}">线上云展厅</div>
            </div>
          </div>
        </div>
      </div>
      <div id="section4" class="section">
        <div style="height: 100%;overflow: hidden">
          <div class="mobile-animationPage3">
            <div class="title-box" ref="box3">
              <div class="mobile-animationPage-title">项目策划</div>
              <div class="mobile-animationPage-title2">提供全案创意策划+设计实施</div>
              <div class="mobile-animationPage-content">
                两航数科提供全案创意策划+设计实施，充分发挥创意与科技的核心竞争力，助力释放更大市场价值。
              </div>
            </div>

            <div style="position: relative;display: flex;flex-direction: column">
              <div  style="text-align: center;margin-top: 1.22rem;height: 28rem">
                <div class="imgbox3" v-show="mobileImgActive3===1" ref="imgbox3">
                  <div class="parent mobile-img-box parent3">
                    <img src="../assets/cpt/wlyy1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text"></span>
                    </div>
                  </div>
                </div>
                <div class="imgbox3" v-show="mobileImgActive3===2">
                  <div class="parent mobile-img-box parent3">
                    <img src="../assets/cpt/hdch1.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">中汽中心政府智库专家委员会</span>
                    </div>
                  </div>

                </div>
                <div class="imgbox3" v-show="mobileImgActive3===3">
<!--                  <div class="parent mobile-img-box parent3">-->
<!--                    <img src="../assets/cpt/szyx1.png" alt="" class="mobile-animation-img">-->
<!--                    <div class="parent-shadow">-->
<!--                      <span class="parent-shadow-text">滨航企服宣传视频</span>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="parent mobile-img-box parent3">
                    <img src="../assets/cpt/szyx2.png" alt="" class="mobile-animation-img">
                    <div class="parent-shadow">
                      <span class="parent-shadow-text">东丽经开区人才视频</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="position: absolute;left: 0.94rem;top: 4.56rem">
                <div style="width: 1.88rem;height: 1.88rem;margin-bottom: 0.5rem">
                  <img v-if="mobileImgActive3!==1" @click="mobileImgActive3=mobileImgActive3-1" src="../assets/mobileImg/clickTop.png" alt="" style="display: block;width: 100%;height: 100%">
                </div>
                <div style="height: 0.5rem"></div>
                <div style="width: 1.88rem;height: 1.88rem;">
                  <img v-if="mobileImgActive3!==3" @click="mobileImgActive3=mobileImgActive3+1" src="../assets/mobileImg/clickBottom.png" alt="" style="width: 100%;height: 100%;display: block">
                </div>
              </div>
            </div>
            <div style="padding: 0 0.94rem;display: flex;justify-content: space-between;margin-top: 2.47rem;">
              <div @click="mobileImgActive3=1" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive3===1}">文旅夜游</div>
              <div @click="mobileImgActive3=2" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive3===2}">活动策划</div>
              <div @click="mobileImgActive3=3" class="mobile-animation-bottom" :class="{'mobile-animation-bottom-active':mobileImgActive3===3}">数字营销</div>
            </div>
          </div>
        </div>
      </div>
      <div id="section5" class="section">
        <div class="mobile-hzhb">
          <div class="mobile-title" style="text-align: center;padding-top: 1.87rem">合作伙伴</div>
          <div style="display: flex;padding: 0 1.19rem;margin-top: 1.25rem">
            <div class="mobile-hzhb-item">
              <img src="../assets/mobileImg/hzhbitem1.png" alt="" class="hzhb-item-img">
            </div>
            <div class="mobile-hzhb-item" style="margin: 0 0.42rem">
              <img src="../assets/mobileImg/hzhbitem2.png" alt="" class="hzhb-item-img">
            </div>
            <div class="mobile-hzhb-item">
              <img src="../assets/mobileImg/hzhbitem3.png" alt="" class="hzhb-item-img">
            </div>
          </div>
          <div style="display: flex;padding: 0 1.19rem;margin: 0.42rem 0">
            <div class="mobile-hzhb-item">
              <img src="../assets/mobileImg/hzhbitem4.png" alt="" class="hzhb-item-img">
            </div>
            <div class="mobile-hzhb-item" style="margin: 0 0.42rem">
              <img src="../assets/mobileImg/hzhbitem5.png" alt="" class="hzhb-item-img">
            </div>
            <div class="mobile-hzhb-item">
              <img src="../assets/mobileImg/hzhbitem6.png" alt="" class="hzhb-item-img">
            </div>
          </div>
          <div style="display: flex;padding: 0 1.19rem">
            <div class="mobile-hzhb-item">
              <img src="../assets/mobileImg/hzhbitem7.png" alt="" class="hzhb-item-img">
            </div>
            <div class="mobile-hzhb-item" style="margin: 0 0.42rem">
              <img src="../assets/mobileImg/hzhbitem8.png" alt="" class="hzhb-item-img">
            </div>
          </div>
        </div>
        <div class="mobile-footer">
          <div style="display: flex;justify-content: space-between;padding: 0 0.91rem">
            <div style="margin-top: 2.28rem">
              <div style="font-size: 0.56rem;font-weight: 500;color: #fff;margin-bottom: 0.66rem;">
                <p>关注或联系我们</p>
                <p style="margin: 0.44rem 0">添加两航数科官方微信公众号</p>
                <p>更多资讯服务案例等您开启</p>
              </div>
              <div ref="mobileJoinUs" @touchstart="mobileJoinUsStart" @touchend="mobileJoinUsEnd" style="width: 6.91rem;height: 1.63rem;line-height: 1.63rem;text-align: center;border: 1px solid #f2f2f2;border-radius: 0.25rem;color: #fff">加入我们</div>
            </div>
            <div style="width: 5.56rem;height: 5.56rem;margin-top: 2rem">
              <img src="../assets/img/ewm.jpg" alt="" style="width: 100%;height: 100%">
            </div>
          </div>
          <div style="width: 20rem;height: 0.03rem;background-color: #f3f3f3;opacity: 0.2;margin: 1.44rem auto"></div>
          <div style="margin-bottom: 1.44rem;">
            <div style="color: #fff;padding: 0 0.91rem;margin-bottom: 1.4rem;">
              <div style="font-weight: 400;font-size: 0.75rem;margin-bottom: 0.5rem">联系方式</div>
              <div style="font-weight: 500;font-size: 0.88rem;">电话：15222555444</div>
            </div>
            <div style="color: #fff;padding: 0 0.91rem;margin-bottom: 1.4rem;">
              <div style="font-weight: 400;font-size: 0.75rem;margin-bottom: 0.5rem">邮箱</div>
              <div style="font-weight: 500;font-size: 0.88rem;">lhskgzh@163.com</div>
            </div>
            <div style="color: #fff;padding: 0 0.91rem;">
              <div style="font-weight: 400;font-size: 0.75rem;margin-bottom: 0.5rem">地址</div>
              <div style="font-weight: 500;font-size: 0.88rem;">天津市东丽区外环东路3699号码滨航腾云创意园B座</div>
            </div>
          </div>
          <div style="width: 20rem;height: 0.03rem;background-color: #f3f3f3;opacity: 0.2;margin:auto"></div>
          <div class="mobile-footer-bottom">
            <p>两航(天津)数字科技有限公司</p>
            <p>ICP证150476号|津ICP备11018762号</p>
            <p>津公网安备号11010802020287</p>
          </div>
        </div>
      </div>
<!--      移动端结束-->
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      szzc: require('../assets/img/szzc.png'),
      ptkf: require('../assets/img/ptkf.png'),
      xmch: require('../assets/img/xmch.png'),
      szzchover: require('../assets/img/szzchover.png'),
      ptkfhover: require('../assets/img/ptkfhover.png'),
      xmchhover: require('../assets/img/xmchhover.png'),
      imagesActive: 1,
      imagesActive2: 1,
      imagesActive3: 1,
      mobileImgActive: 1,
      mobileImgActive2: 1,
      mobileImgActive3: 1,
      timeoutId1: '',
      timeoutId2: '',
      timeoutId3: '',
      Width: 2100,
      Width2: 700,
      mediaQueryPC: true,
      mobilePage:1,
      showExpand: false,
      // showYWMK: false,
    }
  },
  watch:{
    mobileImgActive:(newV,oldV) => {
      let imgbox1 = document.getElementsByClassName('imgbox1')
      let imgs = imgbox1[newV-1].querySelectorAll('.parent1')
      let allimg = []
      for (let index = 0; index < imgbox1.length; index++){
        allimg.push(imgbox1[index].querySelectorAll('.parent1'))
      }
      allimg.forEach((item) => {
        item.forEach((img) => {
          img.classList.remove("mobile-animation-img-active");
          img.classList.remove("animate-img");
        });
      });
      imgs.forEach((item,index) => {
        setTimeout(() => {
          item.classList.add("mobile-animation-img-active");
        }, index * 330)
      })
    },
    mobileImgActive2:(newV,oldV) => {
      let imgbox1 = document.getElementsByClassName('imgbox2')
      let imgs = imgbox1[newV-1].querySelectorAll('.parent2')
      let allimg = []
      for (let index = 0; index < imgbox1.length; index++){
        allimg.push(imgbox1[index].querySelectorAll('.parent2'))
      }
      allimg.forEach((item) => {
        item.forEach((img) => {
          img.classList.remove("mobile-animation-img-active");
          img.classList.remove("animate-img");
        });
      });
      imgs.forEach((item,index) => {
        setTimeout(() => {
          item.classList.add("mobile-animation-img-active");
        }, index * 330)
      })
    },
    mobileImgActive3:(newV,oldV) => {
      let imgbox1 = document.getElementsByClassName('imgbox3')
      let imgs = imgbox1[newV-1].querySelectorAll('.parent3')
      let allimg = []
      for (let index = 0; index < imgbox1.length; index++){
        allimg.push(imgbox1[index].querySelectorAll('.parent3'))
      }
      allimg.forEach((item) => {
        item.forEach((img) => {
          img.classList.remove("mobile-animation-img-active");
          img.classList.remove("animate-img");
        });
      });
      imgs.forEach((item,index) => {
        setTimeout(() => {
          item.classList.add("mobile-animation-img-active");
        }, index * 330)
      })
    }
  },
  mounted () {
    this.handleResize()
    this.init()
    this.$nextTick(()=> {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.9, // 目标元素50%进入视图时触发回调函数
      };
      const observer = new IntersectionObserver(this.animateOnce, options);
      const target = this.$refs.box1
      const target2 = this.$refs.box2
      const target3 = this.$refs.box3
      observer.observe(target);
      observer.observe(target2);
      observer.observe(target3);
    })

    this.$nextTick(()=> {
      // console.log(this.$refs.imgbox3.querySelectorAll(".mobile-animation-img"))
      const options1 = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // 目标元素50%进入视图时触发回调函数
      };
      const observer = new IntersectionObserver(this.animateImg, options1);
      const target = this.$refs.imgbox1
      const target2 = this.$refs.imgbox2
      const target3 = this.$refs.imgbox3
      observer.observe(target);
      observer.observe(target2);
      observer.observe(target3);
    })
  },
  methods: {
    animateOnce(entries, observer) {
      if (entries[0].isIntersecting) {
        const target = entries[0].target;
        target.classList.add("animate");
        observer.unobserve(target); // 只运行一次动画
      }
    },
    animateImg(entries, observer) {
      if (entries[0].isIntersecting) {
        const target = entries[0].target;
        const imgs = target.querySelectorAll(".parent")
        // const imgs = target.querySelectorAll(".mobile-animation-img")
        imgs.forEach((item, index) => {
          setTimeout(() => {
            item.classList.add("animate-img");
          }, index * 330)
        })
        // target.classList.add("animate-img");
        observer.unobserve(target); // 只运行一次动画
      }
    },
    scrollToAnchor(anchor) {
      this.showExpand = false
      const el = document.querySelector(anchor);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleResize () {
      const widowsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      const mobileWidth = document.documentElement.clientWidth
      this.Width = widowsWidth * 1.09375
      this.Width2 = widowsWidth * 0.3645833333333333
      this.mediaQueryPC = mobileWidth >= 1280
      console.log(mobileWidth)
      const rootFontSize = mobileWidth / 23.4375
      document.documentElement.style.fontSize = rootFontSize + 'px'
    },
    init () {
      window.addEventListener('resize', this.handleResize)
      window.addEventListener('scroll', () => {
        if (this.mediaQueryPC) {
          const head = document.querySelector('.head')
          const headText = document.getElementsByClassName('head-text')
          const bottomDistance = document.documentElement.scrollHeight - (window.pageYOffset + window.innerHeight)
          const percent = window.pageYOffset / 720
          head.style.background = `rgba(21,20,19,${percent})`
          if (window.pageYOffset >= 660) {
            head.classList.add('head-dark')
            for (let i = 0; i < headText.length; i++) {
              headText[i].classList.remove('active')
            }
          } else {
            head.classList.remove('head-dark')
            for (let i = 0; i < headText.length; i++) {
              headText[i].classList.remove('active')
            }
          }
          if (window.pageYOffset < 660) {
            document.getElementsByClassName('home')[0].classList.add('active')
          } else if (window.pageYOffset >= 660 && window.pageYOffset < 1215) {
            document.getElementsByClassName('us')[0].classList.add('active')
          } else if (window.pageYOffset >= 1215 && bottomDistance > 1) {
            document.getElementsByClassName('business')[0].classList.add('active')
          } else if (bottomDistance < 1) {
            document.getElementsByClassName('partner')[0].classList.add('active')
          }
        } else {
          const mobileHead = document.querySelector('.mobile-head')
          const rem = document.documentElement.clientWidth / 23.4375
          const mobilePercent = window.pageYOffset / ((rem * 24.06) - (rem * 3.125))
          mobileHead.style.background = `rgba(21,20,19,${mobilePercent})`
        }
      })
    },
    scrollTo (x, y) {
      window.scroll({
        top: y,
        left: x,
        behavior: 'smooth'
      })
    },
    mouseIn1 () {
      this.imagesActive = 1
      clearTimeout(this.timeoutId1)
      const item = document.getElementsByClassName('item-thumb')
      const img1 = document.querySelectorAll('.scene-wrapper-img')[0]
      img1.style.backgroundImage = 'url(' + this.ptkfhover + ')'
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '0'
      }
    },
    mouseOut1 () {
      const item = document.getElementsByClassName('item-thumb')
      const item2 = document.getElementsByClassName('item-hover')
      const img1 = document.querySelectorAll('.scene-wrapper-img')[0]
      this.timeoutId1 = setTimeout(() => {
        img1.style.backgroundImage = 'url(' + this.ptkf + ')'
      }, 1000)
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '1'
        item2[i].style.transitionDelay = '0s'
      }
      setTimeout(function () {
        for (let i = 0; i < item.length; i++) {
          item2[i].style.transitionDelay = '0.5s'
        }
      }, 100)
    },
    mouseIn2 () {
      this.imagesActive2 = 1
      clearTimeout(this.timeoutId2)
      const item = document.getElementsByClassName('item-thumb')
      const img2 = document.querySelectorAll('.scene-wrapper-img')[1]
      img2.style.backgroundImage = 'url(' + this.szzchover + ')'
      img2.style.left = '-140px'
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '0'
      }
    },
    mouseOut2 () {
      const item = document.getElementsByClassName('item-thumb')
      const item2 = document.getElementsByClassName('item-hover')
      const img2 = document.querySelectorAll('.scene-wrapper-img')[1]
      this.timeoutId2 = setTimeout(() => {
        img2.style.backgroundImage = 'url(' + this.szzc + ')'
        img2.style.left = '-580px'
      }, 1000)
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '1'
        item2[i].style.transitionDelay = '0s'
      }
      setTimeout(function () {
        for (let i = 0; i < item.length; i++) {
          item2[i].style.transitionDelay = '0.5s'
        }
      }, 100)
    },
    mouseIn3 () {
      this.imagesActive3 = 1
      clearTimeout(this.timeoutId3)
      const item = document.getElementsByClassName('item-thumb')
      const img3 = document.querySelectorAll('.scene-wrapper-img')[2]
      img3.style.backgroundImage = 'url(' + this.xmchhover + ')'
      img3.style.left = '-140px'
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '0'
      }
    },
    mouseOut3 () {
      const item = document.getElementsByClassName('item-thumb')
      const item2 = document.getElementsByClassName('item-hover')
      const img3 = document.querySelectorAll('.scene-wrapper-img')[2]
      this.timeoutId3 = setTimeout(() => {
        img3.style.backgroundImage = 'url(' + this.xmch + ')'
        img3.style.left = '-230px'
      }, 1000)
      for (let i = 0; i < item.length; i++) {
        item[i].style.opacity = '1'
        item2[i].style.transitionDelay = '0s'
      }
      setTimeout(function () {
        for (let i = 0; i < item.length; i++) {
          item2[i].style.transitionDelay = '0.5s'
        }
      }, 100)
    },
    mobileJoinUsStart(){
      this.$refs.mobileJoinUs.style.background = '#fff'
      this.$refs.mobileJoinUs.style.border = '1px solid #fff'
      this.$refs.mobileJoinUs.style.color = '#000'
    },
    mobileJoinUsEnd(){
      this.$refs.mobileJoinUs.style.background = 'transparent'
      this.$refs.mobileJoinUs.style.border = '1px solid #fff'
      this.$refs.mobileJoinUs.style.color = '#fff'
    }
  }
}
</script>
<style>

body::-webkit-scrollbar{
  display: none;
}
body {
  position: relative;
  top: 0;
  height: 100%;
  margin: 0;
  padding: 0;
}

.logo {
  width: 140px;
  height: 32px;
  position: absolute;
  top: 15px;
  left: 370px;
  user-select: none;
}

.head-dark {
  background-color: #151413 !important;
  border-bottom: 1px solid #151413 !important;
}
.head {
  /*position: fixed;*/
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1964);
  z-index: 999;
  color: #FFFFFF;
  background: rgba(21, 20, 19, 0.1);
}

.head-text {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
}

.home {
  position: absolute;
  left: 1191px;
}

.us {
  position: absolute;
  left: 1268px;
}

.business {
  position: absolute;
  left: 1377px;
}

.partner {
  position: absolute;
  left: 1486px;
}

.active {
  color: #71C6D4 !important;
}

.banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 720px;
  /*background-image: url(@/assets/img/bg.png);*/
  /*background-size: 100% 100%;*/
}

.banner-text {
  position: absolute;
  top: 278px;
  left: 370px;
  width: 273px;
  height: 164px;
  font-size: 68px;
  font-family: DingTalk JinBuTi-Regular, DingTalk JinBuTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 82px;
  z-index: 20;
  user-select: none;
}

.banner-text-shade {
  position: absolute;
  top: 283px;
  left: 376px;
  width: 276px;
  height: 154px;
  font-size: 64px;
  font-family: DingTalk JinBuTi-Regular, DingTalk JinBuTi;
  font-weight: 400;
  color: rgba(255, 255, 255, 0);
  line-height: 77px;
  letter-spacing: 4px;
  -webkit-text-stroke: 1px #0088FF;
  text-stroke: 1px #0088FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
}

.aboutUs {
  width: 100%;
  height: 486px;
}

.title {
  text-align: center;
  font-size: 28px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #151413;
  margin: 70px 0 39px 0;
}

.aboutUs-text {
  width: 1032px;
  height: 78px;
  font-size: 16px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #41403F;
  line-height: 26px;
  margin: 0 auto 57px;
  text-align: center;
}

.aboutUs-icon-box {
  width: 100px;
  height: 140px;
  user-select: none;
}

.aboutUs-icon {
  display: block;
  margin: 0 auto 20px;
}

.aboutUs-icon-text {
  display: inline-block;
  font-size: 20px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #151413;
}

.businessBox {
  /*width: 2100px;*/
  height: 800px;
  display: flex;
  overflow: hidden;
  /*position: relative;*/
}

.scene-wrapper:nth-child(1) .scene-wrapper-img{
  width:100%;
  left: 0;
  background-image: url("../assets/img/ptkf.png");
}
.scene-wrapper:nth-child(2) .scene-wrapper-img{
  width: 1300px;
  left: -580px;
  background-image: url("../assets/img/szzc.png");
}
.scene-wrapper:nth-child(3) .scene-wrapper-img{
  width: 784px;
  left: -230px;
  background-image: url("../assets/img/xmch.png");
}
.scene-wrapper-img{
  position: absolute;
  top: 0;
  /*left: -140px;*/
  width:1300px;
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
  transform: skew(8deg);
  transform-origin: left top;
  transition: width 1s;
}
.scene-wrapper{
  position: relative;
  z-index: 99;
  width: 700px;
  height: 100%;
  box-sizing: border-box;
  transform: skew(-8deg);
  transform-origin: left top;
  transition: all 1s;
  border-right: 1px solid rgba(255,255,255,0.2);
  background: transparent;
  overflow: hidden;
}

/*.scene-wrapper1:hover .scene-wrapper-img{*/
/*  background-image: url("../assets/img/szzchover.png");*/
/*}*/

/*.scene-wrapper2:hover .scene-wrapper-img{*/
/*  background-image: url("../assets/img/szzchover.png");*/
/*  left: -140px;*/
/*}*/

/*.scene-wrapper3:hover .scene-wrapper-img{*/
/*  background-image: url("../assets/img/xmchhover.png");*/
/*  left: -140px;*/
/*}*/

.scene-wrapper:hover{
  width: 3500px !important;
}

.scene-wrapper:hover .scene-wrapper-img{
  width: 1800px !important;
}

 .scene-wrapper-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.scene-wrapper:hover .item-thumb{
  opacity: 0;
}
.scene-wrapper:hover .item-hover{
  opacity: 1;
}

.hover-image{
  width: 302px;
  height: 182px;
  margin-right: 28px;
  border: 1px solid #fff;
}

.hover-noneactive{
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
  opacity: .7;
  color: rgba(255,255,255,0.7);
}

.hover-active{
  opacity: 1;
  font-size: 20px !important;
  color: #fff !important;
}

.item-thumb{
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 70%;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(360deg,rgba(37, 53, 133, 1) 0,rgba(37, 53, 133, 0) 100%);
  transition: opacity .5s;

}
.item-hover{
  position:relative;
  z-index: 20;
  width: 1500px;
  /*position: absolute;*/
  /*top: 140px;*/
  /*left: 0;*/
  opacity: 0;
  transition: opacity .5s;
  transition-delay: 0.5s;
  margin-left: 100px;
  transform: skew(8deg) translateY(140px);
  box-sizing: border-box;
}

.hover-bottom{
  margin-top: 136px;
  display: flex;
}
.hover-bottom-right{
  display: flex;
  margin-left: 58px;
}
.hover-image-text{
  font-size: 16px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: -10px;
}
.hover-title{
  font-size: 52px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
}
.hover-text{
  width: 650px;
  font-size: 16px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 21px;
}
.businessBox-title {
  font-size: 52px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
  transform: skew(8deg);
}

.businessBox-text {
  font-size: 16px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 10px;
  transform: skew(8deg);
}

.partnerBox-item {
  width: 280px;
  height: 110px;
  border: 2px solid #FFFFFF;
  box-shadow: 2px 2px 10px 0px rgba(142, 192, 255, 0.2);
  border-radius: 4px;
  background: #FFFFFF;

  display: flex;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
}

.partnerBox-item img {
  object-fit: contain; /*保持图片原始比例*/
}

.footer {
  width: 100%;
  height: 310px;
}

.footer-top {
  width: 100%;
  height: 254px;
  background: url("@/assets/img/footer-bg.png");
  background-size: 100% 100%;
  display: flex;
}

.footer-bottom {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: #FFFFFF;
  text-align: center;
}

.footer-bottom span {
  display: inline-block;
  font-size: 12px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #41403F;
  user-select: none;
}

.contactInformation {
  font-size: 12px;
  font-family: PingFangSC-Regular-Regular, PingFangSC-Regular;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 370px;
  padding-top: 48px;
}

.joinUs {
  margin-left: 567px;
  padding-top: 48px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
}

.joinUs-left {
  width: 82px;
  height: 82px;
  background-image: url("@/assets/img/jx.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.joinUs-left img {
  width: 74px;
  height: 74px;
}

.joinUs-right {
  margin-left: 22px;
}
.joinUs-button{
  width: 194px;
  height: 34px;
  display: flex;
  background: transparent;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 6px;
  color: #fff
}
.joinUs-button:hover{
  background: #fff !important;
  color: #253585 !important;
}

.el-carousel__indicator .el-carousel__button{
  width: 23px;
  height: 4px;
  transform: skew(-50deg);
  margin-right: 6px;
}

/*移动端样式*/
.mobile-head {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1964);
  z-index: 999;
  color: #FFFFFF;
  background: rgba(21, 20, 19, 0.1);
}
.mobile-title{
  font-size: 1.13rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1A1A1A;
}
.mobile-usImg{
  width: 2.72rem;
  height: 3.53rem;
  display: block;
  margin: 0 auto 0.5rem;
}

.mobile-animationPage{
  position: relative;
  top: 0;
  width: 23.44rem;
  height: 48.31rem;
  background: url("../assets/mobileImg/page1.png");
  background-size: 100% 100%;
  padding-top: 3.25rem;
  box-sizing: border-box;
}
.mobile-animationPage2{
  position: relative;
  top: 0;
  width: 23.44rem;
  height: 48.31rem;
  background: url("../assets/mobileImg/page2.png");
  background-size: 100% 100%;
  padding-top: 3.25rem;
  box-sizing: border-box;
}
.mobile-animationPage3{
  position: relative;
  top: 0;
  width: 23.44rem;
  height: 48.31rem;
  background: url("../assets/mobileImg/page3.png");
  background-size: 100% 100%;
  padding-top: 3.25rem;
  box-sizing: border-box;
}

.mobile-animationPage-title{
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  margin-left: 2.7rem;
}

.mobile-animationPage-title2{
  width: 18.59rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: PingFang SC;
  color: #FFFFFF;
  line-height: 1.88rem;
  margin-left: 2.7rem;
  margin-top: 0.81rem;
  margin-bottom: 2rem;
}

.mobile-animationPage-content{
  width: 17.19rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-left: 2.7rem;
  line-height: 1.25rem;
}

.mobile-animation-img{
  width: 15.47rem;
  height: 8.66rem;
  border: 1px solid #fff;
  margin-bottom: 1rem;
  /*opacity: 0;*/
  /*transition: opacity .7s, transform .7s;*/
  /*transform: translateY(20px);*/
}

.mobile-animation-img-active{
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.mobile-animation-img:last-child{
  margin-bottom: 0;
}

.mobile-animation-bottom{
  width: 4.16rem;
  height: 1.16rem;
  line-height: 1.16rem;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  border-radius: 0.52rem;
  border: 1px solid transparent;
}

.mobile-animation-bottom-active{
  border: 1px solid #5EB9C8 !important;
  color: #5EB9C8;
  background: rgba(0,0,0,0.63);
}

.mobile-hzhb{
  width: 100%;
  height: 13.16rem;
  background: #f3f3f3;
}
.mobile-hzhb-item{
  width:6.72rem;
  height: 2.34rem;
}
.hzhb-item-img{
  width: 100%;
  height: 100%;
}
.mobile-footer{
  width:100%;
  height: 27.28rem;
  background: url("../assets/mobileImg/footerbg.png");
  background-size: cover;
}
.mobile-footer-bottom{
  height: 2.72rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1rem;
  opacity: 0.5;
  text-align: center;
  margin: 0.2rem auto 0;
  user-select: none;
}
.mobile-expand{
  width: 33%;
  /*height: 14.5rem;*/
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #151413;
  opacity: 0.9;
  font-size: 0.88rem;
  font-weight: 600;
  color: #fff;
  /*padding: 2rem 0;*/
  transition: all 1s;
  float: right;
}

.title-box {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(20px);
}

.animate {
  opacity: 1;
  transform: translateY(0);
}

.mobile-img-box{
  opacity: 0;
  transition: opacity .7s, transform .7s;
  transform: translateY(20px);
}

.animate-img{
  opacity: 1;
  transform: translateY(0);
}

.mobile-jrwm{
  line-height: 1rem;
}

.parent{
  position: relative;
}

.parent-shadow{
  position: absolute;
  left: 4rem;
  top: 0;
  width: 15.44rem;
  height: 8.69rem;
  background: linear-gradient(to bottom, transparent 83%, #303030);
}

.parent-shadow-text{
  display: block;
  font-size: 0.63rem;
  font-weight: 500;
  margin-top: 7.40rem;
  margin-left: 0.5rem;
  color: #fff;
  text-align: left;
}
</style>
