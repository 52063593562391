<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="less">
*{
  margin:0;
  padding: 0;
}
</style>
